<template lang="pug">
  tr.course-leader_item.total
    td.item_total TOTAL
    td.item_quantity-course(v-if="isByCourseLeadersTab") {{ total.course_count_total | zeroValue }}
    td.item_quantity-course(v-if="isByCourseLeadersTab") {{ total.has_course_photo | zeroValue }}
    td.item_null(v-else)

    td.item_success {{ total.success_total | percentValue }}
    td.item_success-days {{ total.success_same_day_total | percentValue }} | {{ total.success_other_day_total | percentValue }}

    td.item_presentie {{ total.presentie_total | zeroValue }}{{ total.presentie_notignore_total | zeroValueWithBrackets }}

    td.item_answers(v-if="isByCourseLeadersTab") {{ total.filtered_by_answers_total | zeroValue }} | {{ total.filtered_by_answers_percents_total | percentValue }}

    td.item_presentie-days {{ total.presentie_notignore_same_day_total | zeroValue }} | {{ total.presentie_notignore_other_day_total | zeroValue }}

    td.item_absentie {{ total.absentie_total }}
    td.item_zero-mistakes {{ total.zero_mistakes_total }}

    td.item_failed {{ total.failed_total | zeroValue }}{{ total.failed_notignore_total | zeroValueWithBrackets }}
    td.item_failed-days {{ total.failed_notignore_same_day_total | zeroValue  }} | {{ total.failed_notignore_other_day_total | zeroValue }}

    td.item_passed {{ total.passed_total }}
    td.item_passed-days {{ total.passed_notignore_same_day_total | zeroValue  }} | {{ total.passed_notignore_other_day_total | zeroValue }}

    td.item_average-rating {{ total.rating_total | zeroValue }}
</template>

<script>
import { COURSE_LEADERS_TABS_LIST, LEADERS_QUERY_TAB } from '../../core/courseLeadersConst'
import errorsMixin from '@/mixins/errors.mixin'

export default {
  props: {
    total: {
      type: Object,
    }
  },

  mixins: [errorsMixin],

  computed: {
    isByLeaderOnCourseTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADER_ON_COURSE;
    },

    isByCourseLeadersTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADERS;
    }
  },

  data: () => {
    return {
      loading: false,
      COURSE_LEADERS_TABS_LIST,
      LEADERS_QUERY_TAB
    }
  },

  filters: {
    percentValue: function (value) {
      if (!value) return '-'
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1) + '%'
    },
    zeroValueWithBrackets: function (value) {
      if (!value) return ''
      value = value.toString()
      return '(' + value.charAt(0).toUpperCase() + value.slice(1) + ')'
    },
    zeroValue: function (value) {
      if (!value) return '-'
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  }
}
</script>

<style lang="scss" scoped>
.course-leader_item.total {
  background: #f1f2f6 !important;
  border-top: 1px solid #aeaeae;

  td {
    font-weight: bold;

    &.item_answers {
      min-width: 140px;
    }
  }
}
</style>
